import React from 'react';
import type { ClientActionFunctionArgs } from '@remix-run/react';
import { Form, useNavigation, useParams } from '@remix-run/react';
import type { LoaderFunctionArgs } from '@remix-run/server-runtime';
import { json } from '@remix-run/server-runtime';
import { Button } from '~/components/Button';
import { Card, CardContent } from '~/components/Card';
import { FormControl, FormItem, FormLabel, FormMessage } from '~/components/FormNew';
import { AtSign } from '~/components/Icons';
import { Input } from '~/components/Input';
import { Logo } from '~/components/Logo';
import { Line } from '~/components/ServerError';
import { EMAIL_PLACEHOLDER } from '~/constants';
import { authenticator } from '~/utils/auth.server';
import { mergeMeta } from '~/utils/merge-meta';
import { namedAction } from '~/utils/named-action.server';
import { SearchParams } from '~/utils/search-params';
import { returnToCookie, sessionStorage } from '~/utils/session.server';

const REDIRECT = '/dashboard';

export const meta = mergeMeta(() => [
  {
    title: 'Login | Colette',
  },
]);

const getRedirectTo = (url: string) => new URL(url).searchParams.get('redirectTo') || REDIRECT;

export const action = async ({ request }: ClientActionFunctionArgs) => {
  return await namedAction(request, {
    async login() {
      return await authenticator.authenticate('form', new Request(request), {
        successRedirect: getRedirectTo(request.url),
        throwOnError: true,
      });
    },
  });
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  await authenticator.isAuthenticated(request, {
    successRedirect: getRedirectTo(request.url),
  });

  let session = await sessionStorage.getSession(request.headers.get('cookie'));
  let error = session.get(authenticator.sessionErrorKey) as Error | undefined;
  const searchParams = new SearchParams<{ redirectTo: string }>(request.url);

  return json(
    { error: error?.message },
    {
      headers: {
        'Set-Cookie': await returnToCookie.serialize({
          redirectTo: searchParams.get('redirectTo') || REDIRECT,
        }),
      },
    },
  );
};

export default function Login() {
  const navigation = useNavigation();
  const { redirectTo } = useParams();
  const redirect = redirectTo || REDIRECT;
  const [withEmail, setWithEmail] = React.useState(false);
  const isSubmitting =
    navigation.state === 'submitting' && navigation.formAction.endsWith('/login');

  const isSigningWithGoogle =
    navigation.state === 'submitting' && navigation.location.pathname === '/auth/google';

  return (
    <main className="size-screen flex items-center justify-center overflow-hidden px-12">
      <div className="absolute inset-y-0 left-0 z-50 w-1/12 bg-gradient-to-r from-white from-15% to-transparent md:w-1/4" />
      <div className="absolute inset-y-0 right-0 z-50 w-1/12 bg-gradient-to-l from-white from-15% to-transparent md:w-1/4" />
      <div className="absolute inset-x-0 top-0 z-50 h-1/6 bg-gradient-to-t from-transparent from-15% to-white" />
      <div className="absolute inset-x-0 bottom-0 z-50 h-1/6 bg-gradient-to-b from-transparent from-15% to-white" />

      <div className="grid grid-flow-row auto-rows-[minmax(0,_48px)] grid-cols-[repeat(12,_minmax(0,_48px))]">
        <div className="relative col-span-2 col-end-13 row-span-1 row-start-1 md:row-span-2">
          <Logo className="relative z-10 aspect-square size-full [&_path]:origin-center [&_path]:animate-grow [&_path]:fill-white [&_path]:stroke-white [&_path]:stroke-[4] [&_path]:delay-0" />
          <Line className="right-0" direction="vertical" />
          <Line className="top-0" direction="horizontal" />
          <Line className="bottom-0" direction="horizontal" />
        </div>

        <div className="relative col-span-10 col-start-1 row-span-2 row-start-2 md:row-span-1 md:row-start-3">
          <h1
            className="relative z-10 -ml-[3px] -mt-2 block p-0 text-[clamp(3.5rem,_5vw,_3rem)] font-semibold leading-none md:-ml-1.5 md:mt-0 md:text-[clamp(4.25rem,_5vw,_3rem)] md:leading-[0.7]"
            style={{
              letterSpacing: 'clamp(-7px,calc(-1px - 1vw),-4.6px)',
            }}
          >
            Login to Colette
          </h1>
          <Line direction="vertical" className="left-0" />
          <Line
            direction="vertical"
            className="right-0 bg-transparent"
            style={{
              backgroundImage:
                'linear-gradient(to bottom, hsl(var(--border)) 1px, transparent 1px)',
              backgroundSize: '1px 2px',
            }}
          />
          <Line
            direction="horizontal"
            className="bottom-0 bg-transparent"
            style={{
              backgroundImage: 'linear-gradient(to right, hsl(var(--border)) 1px, transparent 1px)',
              backgroundSize: '2px 1px',
            }}
          />
        </div>

        <div className="relative col-span-full col-start-1 row-start-5 row-end-13 flex flex-col">
          <Card className="flex-1 rounded-[48px] shadow-none">
            <Line className="top-0" direction="horizontal" />
            <Line className="bottom-0" direction="horizontal" />

            <CardContent className="flex h-full flex-col items-center justify-center gap-4 pt-6">
              {withEmail ? (
                <Form method="post" action="?/login" className="w-full max-w-80">
                  <FormItem className="mb-4">
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input type="email" name="email" placeholder={EMAIL_PLACEHOLDER} required />
                    </FormControl>
                    <FormMessage />
                  </FormItem>

                  <FormItem className="mb-6">
                    <FormLabel>Password</FormLabel>
                    <FormControl>
                      <Input type="password" name="password" required />
                    </FormControl>
                    <FormMessage />
                  </FormItem>

                  <div className="flex flex-col gap-4 md:flex-row-reverse">
                    <Button
                      variant="default"
                      type="submit"
                      loading={isSubmitting}
                      disabled={isSigningWithGoogle}
                      className="w-full"
                    >
                      Login
                    </Button>

                    <Button
                      variant="ghost"
                      onClick={() => setWithEmail(false)}
                      disabled={isSubmitting}
                      className="w-full md:basis-2/4"
                    >
                      Back
                    </Button>
                  </div>
                </Form>
              ) : (
                <>
                  <Form method="post" action="/auth/google" className="flex w-full justify-center">
                    <Button
                      variant="outline"
                      size="lg"
                      className="h-12 w-full max-w-80 gap-3 text-lg md:text-xl"
                      loading={isSigningWithGoogle}
                      name="redirectTo"
                      value={redirect}
                    >
                      <svg className="aspect-square size-5 min-w-5" viewBox="0 0 18 18">
                        <path
                          fill="#4285f4"
                          fillOpacity="1"
                          fillRule="evenodd"
                          stroke="none"
                          d="M17.64 9.2c0-.637-.057-1.251-.164-1.84H9v3.481h4.844c-.209 1.125-.843 2.078-1.796 2.717v2.258h2.908c1.702-1.567 2.684-3.874 2.684-6.615z"
                        />
                        <path
                          fill="#34a853"
                          fillOpacity="1"
                          fillRule="evenodd"
                          stroke="none"
                          d="M9.003 18c2.43 0 4.467-.806 5.956-2.18l-2.909-2.26c-.806.54-1.836.86-3.047.86-2.344 0-4.328-1.584-5.036-3.711H.96v2.332C2.44 15.983 5.485 18 9.003 18z"
                        />
                        <path
                          fill="#fbbc05"
                          fillOpacity="1"
                          fillRule="evenodd"
                          stroke="none"
                          d="M3.964 10.712c-.18-.54-.282-1.117-.282-1.71 0-.593.102-1.17.282-1.71V4.96H.957C.347 6.175 0 7.55 0 9.002c0 1.452.348 2.827.957 4.042l3.007-2.332z"
                        />
                        <path
                          fill="#ea4335"
                          fillOpacity="1"
                          fillRule="evenodd"
                          stroke="none"
                          d="M9.003 3.58c1.321 0 2.508.454 3.44 1.345l2.582-2.58C13.464.891 11.428 0 9.002 0 5.485 0 2.44 2.017.96 4.958L3.967 7.29c.708-2.127 2.692-3.71 5.036-3.71z"
                        />
                      </svg>
                      Continue with Google
                    </Button>
                  </Form>
                  <Button
                    variant="secondary"
                    size="lg"
                    className="h-12 w-full max-w-80 gap-3 text-lg md:text-xl"
                    disabled={isSigningWithGoogle}
                    onClick={() => setWithEmail(true)}
                  >
                    <AtSign className="size-5 min-w-5 stroke-[2.5]" /> Continue with Email
                  </Button>
                </>
              )}
            </CardContent>
          </Card>
        </div>
      </div>
    </main>
  );
}
